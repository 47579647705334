//import axios from '//plugins/axios.js';

function api() {
    this.post = function (controller, data) {
        return new Promise(function (resolve) {
            axios.post('/' + controller, data).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                if (error.response && error.response.data) {
                    resolve(error.response.data);
                } else {
                    resolve(false);
                }
                //resolve(error.response.data ?? false);
            });
        });
    }

    this.put = function (controller, data) {
        return new Promise(function (resolve) {
            axios.put('/' + controller, data).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                if (error.response && error.response.data) {
                    resolve(error.response.data);
                } else {
                    resolve(false);
                }
                //resolve(error.response.data ?? false);
            });
        });
    }

    this.get = function (controller, data) {
        return new Promise(function (resolve) {
            let params = data;

            axios.get('/' + controller, { params }).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                if (error.response && error.response.data) {
                    resolve(error.response.data);
                } else {
                    resolve(false);
                }
                //resolve(error.response.data ?? false);
            });
        });
    }

    /* Jonas put this in other branch; remove if other style is preferred. 
    this.put = function (controller, resourceUuid, data) {
        return new Promise(function (resolve) {
            axios.put(NEMPOS_API_URL + '/' + controller + '/' + resourceUuid, data).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                resolve(error.response.data ?? false);
            });
        });
    } */

    this.delete = function (controller, data) {
        return new Promise(function (resolve) {
            let params = data;

            axios.delete('/' + controller, { params }).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                resolve(error.response.data ?? false);
            });
        });
    }

    this.csrfCookie = function () {
        return new Promise(function (resolve) {
            axios.get(process.env.VUE_APP_API_HOST + '/sanctum/csrf-cookie').then(function (response) {
                resolve(response);
            }).catch(function (error) {
                resolve(error.response.data ?? false);
            });
        });
    }

    this.validateResponse = function (response) {
        return (!response || !response.status) ? false : true;
    }
}

export default api;
