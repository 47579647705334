

const getPriceAfterDiscount = (price, discountPercentage) => price - price * discountPercentage / 100

const calculateTotalPrice = (state) => {
    let basePrice = 0

    if (state.product) {
        basePrice = getPriceAfterDiscount(parseFloat(state.product.price), state.discountPercentage) * state.quantity
    }

    if (state.variant) {
        basePrice = getPriceAfterDiscount(parseFloat(state.variant.price), state.discountPercentage) * state.quantity
    }

    let answersPriceSum = 0
    state.questionAnswers.forEach((answer) => {
        const multiplierType = (answer) ? state.questions[answer.questionIndex].multiplier_type : 1
        let priceMultiplier = 1
        if (multiplierType == 0) {
            priceMultiplier = state.quantity
        }

        if (answer.questionType === 'product_builder_element_radio') {
            answersPriceSum += parseFloat(answer.answer.price) * answer.answer.quantity * priceMultiplier
        } else if (answer.questionType === 'product_builder_element_checkbox') {
            answer.answers.forEach((checkBoxAnswer) => {
                answersPriceSum += parseFloat(checkBoxAnswer.price) * checkBoxAnswer.quantity * priceMultiplier
            })
        }
    })

    let extraIngredientSum = 0
    for (let id in state.extraIngredientsValue) {
        let quantity = state.extraIngredientsValue[id]
        if (quantity > 0) {
            let price = state.product.api_array.extra_ingredients.find(item => item.id === id).price
            let priceAfterDiscount = parseFloat(price)
            extraIngredientSum += priceAfterDiscount * quantity * state.quantity
        }
    }

    return basePrice + answersPriceSum + extraIngredientSum
}

const getProductBuilderQuestions = (state) => {
    if (!state.product) return []

    if (state.variant && state.variant.product_builder) {
        return state.variant.product_builder.questions
    }

    if (state.product.api_array.product_builder.questions) {
        return state.product.api_array.product_builder.questions
    }

    return []

}

export default {
    state: {
        product: false,
        quantity: 1,

        variant: false,
        questions: [],
        questionAnswers: [],

        extraIngredientsValue: {},
        selectedIngredientsToRemove: [],

        discountPercentage: 0,
        totalPrice: 0
    },
    getters: {
    },
    mutations: {
        setProductBuilderProduct(state, product) {
            state.product = product
            state.quantity = product.min_order_quantity ?? 1

            // setting default variant
            if (state.product.api_array.variants.length > 0) {
                state.variant = state.product.api_array.variants[0]
            } else {
                state.variant = false
            }

            state.questions = getProductBuilderQuestions(state)
            state.questionAnswers = new Array(state.questions.length).fill(false)

            state.extraIngredientsValue = {}
            state.selectedIngredientsToRemove = []

            state.discountPercentage = state.product.discount_percentage ?? 1
            state.totalPrice = calculateTotalPrice(state)
        },
        setProductBuilderVariant(state, variant) {
            state.variant = variant

            state.quantity = 1
            state.questions = getProductBuilderQuestions(state)
            state.questionAnswers = new Array(state.questions.length).fill(false)

            state.extraIngredientsValue = {}
            state.selectedIngredientsToRemove = []

            state.totalPrice = calculateTotalPrice(state)
        },
        setProductBuilderQuantity(state, quantity) {
            state.quantity = quantity
            state.totalPrice = calculateTotalPrice(state)
        },
        setProductBuilderQuestionAnswered(state, data) {
            const questionIndex = data.questionIndex
            state.questionAnswers.splice(questionIndex, 1, data)
            state.totalPrice = calculateTotalPrice(state)
        },
        setProductBuilderIngredientsToRemove(state, { checked, ingredientID }) {
            const index = state.selectedIngredientsToRemove.indexOf(ingredientID)
            if (checked) {
                if (index == -1) {
                    state.selectedIngredientsToRemove.push(ingredientID)
                }
            } else {
                if (index !== -1) {
                    state.selectedIngredientsToRemove.splice(index, 1)
                }
            }
            state.totalPrice = calculateTotalPrice(state)
        },
        setProductBuilderIncreaseDecreaseExtraOptions(state, { extraIngredientID, value }) {
            let ingredient = state.product.api_array.extra_ingredients.find(item => item.id === extraIngredientID)
            let maxValue = ingredient.max_ingredients
            let oldValue = state.extraIngredientsValue[extraIngredientID] ?? 0
            let newValue = oldValue + value

            if (value > 0) {
                if (newValue <= maxValue) {
                    state.extraIngredientsValue[extraIngredientID] = newValue
                    state.totalPrice = calculateTotalPrice(state)
                }
            } else {
                if (newValue >= 0) {
                    state.extraIngredientsValue[extraIngredientID] = newValue
                    state.totalPrice = calculateTotalPrice(state)
                }
            }
        },
        reset(state) {
            state.product = false
            state.variant = false
            state.quantity = 1

            state.questions = []
            state.questionAnswers = {}

            state.extraIngredientsValue = {}
            state.selectedIngredientsToRemove = []

            state.discountPercentage = 0
            state.totalPrice = calculateTotalPrice(state)
        }
    },
    actions: {

    }
}
