import { loadLanguageAsync } from "../i18n-setup";

export default {
    state: {
        selectedLanguage: 'da',
        langToCountry: {
            da: 'dk',
            en: 'gb',
        },
    },
    mutations: {
        setSelectedLanguage(state, language) {
            state.selectedLanguage = language;
        },
    },
    actions: {
        setLanguage({ commit }, language) {
            localStorage.nemposLang = language;
            loadLanguageAsync(language);
            commit('setSelectedLanguage', language);
        },
        
        loadLanguageFromStorage({ commit }) {
            const storedLang = localStorage.getItem('nemposLang');
            
            if (storedLang) {
                this.commit('setSelectedLanguage', storedLang);
            }
        }
    },
    getters: {
        getFlagUrl: (state) => (lang) => {
            const countryCode = state.langToCountry[lang];
            
            if (countryCode) {
                return require(`@/assets/flags/${countryCode}.svg`);
            }
            
            console.log('Something is wrong: no language selected, pick danish as default')
            return require('@/assets/flags/dk.svg');
        }
    }
};