<template>
    <generic-modal :showModal="true">
        <template #header v-if="$store.state.takeOverQuery.headerText">
            <h5 class="modal-title" v-html="$store.state.takeOverQuery.headerText"></h5>
            <!-- <button type="button" class="btn-close" aria-label="Close"></button> -->
        </template>

        <template #body v-if="$store.state.takeOverQuery.queryText">
            <p v-html="$store.state.takeOverQuery.queryText"></p>
        </template>

        <template #footer>
            <button @click="cancel" type="button" class="btn btn-secondary">{{ $t('general.cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="confirm">{{ $t('general.confirm') }}</button>
        </template>

    </generic-modal>
</template>

<script lang="js">
import GenericModal from '@/components/admin/GenericModal.vue';

export default {
    name: 'VuexConfirmationModal',

    components: {
        GenericModal,
    },

    data() {
        return {

        }
    },

    computed: {
        //
    },

    /* props: {
        headerText: {
            type: String,
            required: false,
        },

        queryText: {
            type: String,
            required: true,
        },

        confirmClosure: {
            type: Function,
            required: false, // IMPTODO: Change to true if we're using this
        },
    }, */

    methods: {
        cancel() {
            this.setQueryNotActive();
        },

        confirm() {
            this.$store.state.takeOverQuery.confirmClosure();
            this.setQueryNotActive();
        },

        setQueryNotActive() {
            this.$store.commit('setTakeOverQueryNotActive');
        },

        handleKeyDown(event) {
            if (event.key === 'Escape') {
                this.cancel();
            }
        }
    },

    created() {
        window.addEventListener('keydown', this.handleKeyDown);
    },

    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
}
</script>

<style lang="scss" scoped>
.take-over-query {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index: 1000;
    display: block !important;
}
</style>