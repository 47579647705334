<template>
    <!-- <div class="row flex-nowrap"> -->
        <router-view />
    <!-- </div> -->
</template>

<script lang="js">

export default {
    name: 'SignedInApp',

    data() {
        return {
            //
        }
    },

    methods: {
        logout() {
            this.$store.commit('signOut');
            this.$store.commit('resetDataDerivedFromUserInput'); // TODO: Probably not necessary?
            this.sessionStorage.remove('selected-company-uuid');
            localStorage.removeItem('selectedCompanyUuid');
            localStorage.removeItem('authorizationBearer');

            axios.defaults.headers.common['Authorization'] = '';
            axios.defaults.params = {};

            // TODO: The below code only works because of the catch. Figure out what was wrong before.
            this.$router.push('/login').catch(() => true);
            window.location.reload(); // TODO: Empty store gracefully instead
            
        },

        async getUserData(redirect = false) {
            await axios.get('users/get-signed-in-user-data').then(response => {
                let responseData = response.data;
                this.processUserData(responseData);

                if ('company' in responseData) {
                    const finishedSetup = this.$store.getters.companyInitialisationComplete;
                    if (this.$route.name !== 'CompanySetup' && !finishedSetup && this.$store.getters.quickSetupAvailableForCompany) {
                        this.$router.push('/admin/setup-company');
                        return;
                    }

                    if ((redirect && this.$route.name !== 'Dashboard') || (this.$route.name === 'CompanySetup' && finishedSetup)) {
                        this.$router.push({ name: 'Dashboard' });
                        
                        return;
                        // IMPTODO: Handle other cases, e.g. cashier
                    }

                    this.$root.$emit('received-company-data');
                }

                if (this.$route.name === 'home') {
                    this.$router.push({ name: 'Dashboard' });
                }
            }).catch(error => {
                if (error.response) {
                    if (error.response.status != 503) {
                        this.$router.push('/login');
                    } else {
                        //
                    }
                }

                console.log(error);
            });
        },

        setAxiosAuthorizationBearer(token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        },

        setAxiosCompanyUuid(companyUuid) {
            axios.defaults.headers.common['Company-Uuid'] = companyUuid;
            axios.defaults.params = {};
            axios.defaults.params['company_uuid'] = companyUuid;
            axios.defaults.params['VUE_APP_VERSION_KEY'] = process.env.VUE_APP_VERSION_KEY;
            axios.defaults.params['VUE_APP_VERSION_BUILD'] = process.env.VUE_APP_VERSION_BUILD;
        },

        getCompanyUuidFromStorage() {
            if (this.sessionStorage.exists('selected-company-uuid')) {
                return this.sessionStorage.get('selected-company-uuid');
            } else if (localStorage.selectedCompanyUuid) {
                return localStorage.getItem('selectedCompanyUuid');
            } else {
                return null;
            }
        },

        processUserData(data) {
            // If making changes around here, remember to reflect these changes in AutoLogin; I was half-forced to copy
            // code there, since it isn't a child of this component.

            if ('company' in data) {
                this.$store.commit('setCompany', data.company);
                this.sessionStorage.set('selected-company-uuid', data.company.uuid);
                localStorage.setItem('selectedCompanyUuid', data.company.uuid);
                this.setAxiosCompanyUuid(data.company.uuid);
            }

            this.$store.commit('setUser', data.user);

            this.$store.commit('setPendingInvitations', data.pending_invitations);

            if (data.other_companies.length > 0) {
                this.$store.commit('setAlternativeCompanies', data.other_companies);
            }
        },
    },

    computed: {
        viewingAdmin: function() {
            return this.$route.path.startsWith('/admin') ? true : false;
        },
    },

    mounted() {
        let _this = this;

        let authorizationBearer = localStorage.authorizationBearer;

        if (authorizationBearer) {
            this.showError = false;
            this.setAxiosAuthorizationBearer(authorizationBearer);
        }

        this.$root.$on('sign-out-user', function () {
            _this.logout();
        });

        this.$root.$on('refresh-user-data', function () {
            _this.getUserData();
        });
    },

    created() {
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response.status == 401) {
                console.log('401 intercepted');
                this.logout();
            }

            if (error.response.status == 403) {
                if (error.response.data.reason === 'insufficient_subscription') {
                    const params = {
                        reason: 'insufficient_subscription',
                        requiresOneOfPackages: error.response.data.available_in_packages,
                    };

                    this.$router.push({ name: 'Forbidden', params});
                } else if (error.response.data.reason === 'incompatible_company_type') {
                    this.$router.push({ name: 'Forbidden', params: { reason: error.response.data.reason }});
                } else {
                    this.$router.push({ name: 'Forbidden' });
                }
            }

            return Promise.reject(error);
        });

        let token = localStorage.authorizationBearer;

        if (token) {
            this.setAxiosAuthorizationBearer(token);

            const companyUuid = this.getCompanyUuidFromStorage();
            if (companyUuid !== null) {
                this.setAxiosCompanyUuid(companyUuid);
            }

            this.getUserData();
        } else {
            this.$router.push('/login');
        }
    },

    beforeDestroy() {
        //
    }
}
</script>

<style lang="scss">


</style>
