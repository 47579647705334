import Vue from 'vue';
//import axios from 'axios';

export default {
    state: {
        revenueAccounts: [],
    },

    getters: {
        
    },

    mutations: {
        setRevenueAccounts(state, revenueAccounts) {
            state.revenueAccounts = revenueAccounts;
        }
    },

    actions: {
        fetchRevenueAccounts({ commit }, extraData) {
            let params = {
                type: 'revenue',
                designated_purpose: 'none',
                has_vat_types: 1
            };

            params = {...params, ...extraData };

            return  axios.get('accounts', { params }).then(response => {
                commit('setRevenueAccounts', response.data.accounts);
            }).catch(e => {
                commit('warningNotify', 'A product requires a revenue account, but no revenue accounts were found.');
            });
        },
    },
}