
export default class LogService {

    constructor(options =  {}) {
        this.isEnabled = options.isEnabled === true || process.env.ENABLE_LOG === 1 // TODO: check if in not in production
    }

    log(level, message) {
        if (!this.isEnabled) return

        const func = console[level]
        if (func) { func(message) }
    }

    debug   = (message) => this.log('log', message)
    info    = (message) => this.log('info', message)
    warn    = (message) => this.log('warn', message)
    error   = (message) => this.log('error', message)

}