import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from "@/store";

//import './assets/retheme.scss';

//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/scss/bootstrap.scss';

import moment from 'moment'

import 'bootstrap-icons/font/bootstrap-icons.scss';
import 'bootstrap';

import './assets/nempos_bootstrap.scss';
import './assets/main.scss';
//import './registerServiceWorker'
import router from './router'
import sessionStorage from './storage/sessionStorage';
import api from './api';
import LogService from './services/LogService';
import VueApexCharts from 'vue-apexcharts';

//import Popper from 'vue-popperjs';
//import 'vue-popperjs/dist/vue-popper.css';
window.onerror = function(message, url, lineNumber) {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', process.env.VUE_APP_ERR_HOST+'/err/onerror/', [true, null, null]);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({themsg: message,theurl: url, lineno: lineNumber}))
    return true;
};   

Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', process.env.VUE_APP_ERR_HOST+'/err/errorHandler/', [true, null, null]);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({theerr: err,thevm: vm, theinfo: info}))
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
  }
  window.addEventListener('unhandledrejection', function(event) {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', process.env.VUE_APP_ERR_HOST+'/err/unhandledrejection/', [true, null, null]);
    //xhr.send([event.reason.message,event.reason.stack])
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({err:event.reason.message,stack:event.reason.stack}));
});  
Vue.prototype.sessionStorage = new sessionStorage();
Vue.prototype.wsyAPI = new api();
Vue.prototype.moment = moment
Vue.prototype.logger = new LogService({isEnabled: true})

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

//Vue.component('popper', Popper)

import './i18n-setup.js'
import { i18n } from './i18n-setup.js'


new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

