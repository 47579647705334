<template>
    <div class="col-xl-5 col-lg-7 col-md-8 col-sm-10 maintenance-box text-center" ref="maintenanceBox">
        <div v-if="isProcessingCheck" :style="spinnerBoxStyles" class="spinner-box d-flex align-items-center justify-content-center">
            <span class="spinner-border" role="status" aria-hidden="true"></span>
        </div>
        <div class="p-3" v-else>
            <div class="alert alert-warning" v-if="checkFailed">
                {{ $t('maintenance.check_failed') }}
            </div>

            <h3>{{ $t('maintenance.title') }}</h3>

            <p class="lead">
                {{ $t('maintenance.message') }}
            </p>

            <p class="lead" v-if="$store.state.maintenanceMode !== true">
                <!-- This component should only be loaded (verify in App.vue) if maintenanceMode evaluates to true. If the
                value isn't strictly equal to true, it is supposed to be a message to be displayed here. -->
                {{ $t('maintenance.message_from_sysadmin') }}:<br><em>{{ $store.state.maintenanceMode }}</em>
            </p>

            <p class="lead mb-0" v-if="secondsUntilNextCheck !== null">
                {{ $t('maintenance.time_until_next_availability_check') }}<br />
                <b>{{ $t('maintenance.number_of_seconds', { seconds: secondsUntilNextCheck }) }}</b>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                refreshInterval: 10,
                countdownId: false,
                isProcessingCheck: false,
                secondsUntilNextCheck: null,
                spinnerBoxStyles: {},
                checkFailed: false,
            }
        },

        methods: {
            async checkIfStillInMaintenanceMode() {
                this.isProcessingCheck = true;
                //await new Promise(r => setTimeout(r, 20000));
                let response = await this.wsyAPI.get('maintenance-mode-active');

                if (this.wsyAPI.validateResponse(response)) {
                    if ('maintenance' in response) {
                        if (response.maintenance) {
                            this.$store.commit('setMaintenanceMode', response.message === '' ? true : response.message);
                        } else {
                            this.$store.commit('setMaintenanceMode', false);

                            if (this.$route.name !== 'Pos') {
                                this.$router.go();
                            }
                        }
                    }
                } else {
                    this.$root.$emit('show-error', this.$t('pos.failed_fetching_register_data') + ': ' + response.message);
                }

                this.secondsUntilNextCheck = this.refreshInterval;
                this.isProcessingCheck = false;
            },

            matchDimensions() {
                let heightString = this.$refs.maintenanceBox.clientHeight + 'px';
                let widthString = this.$refs.maintenanceBox.clientWidth + 'px';

                this.$set(this.spinnerBoxStyles, 'height', heightString); 
                this.$set(this.spinnerBoxStyles, 'width', widthString);
            },
        },

        mounted() {
            this.secondsUntilNextCheck = this.refreshInterval;

            this.countdownId = setInterval(() => {
                this.secondsUntilNextCheck--;

                if (this.secondsUntilNextCheck === 0) {
                    this.checkIfStillInMaintenanceMode();
                }
            }, 1000);

            // Timeout because we have expand animation
            setTimeout(x => {
                this.matchDimensions();
            }, 1500);
        },

        beforeDestroy() {
            clearInterval(this.countdownId);
        },
    }
</script>

<style lang="scss" scoped>
@import "./src/assets/palettes/_standard.scss";
.maintenance-box {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background-color: $dark-2;
    color: $light-1;
    //bottom: 0;
    z-index: 1000;
    //display: inline-block;
}
</style>