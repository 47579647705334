<template>
    <div class="row h-100">
        <div class="main-container d-flex flex-column align-items-center">
            <div class="login-view mx-auto col-xl-3 col-lg-4 col-md-5 col-sm-8 my-3">
                <img alt="NemPOS logo" src="../assets/NemPOS.png" class="mb-4 d-block mx-auto" style="width: 80%" />
                <div class="form-section">
                    <div v-if="processingLoginRequest || processingSendEmailRequest" :style="spinnerBoxStyles" class="d-flex align-items-center justify-content-center">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                    </div>
                    <form v-else @submit.prevent="mode === 'log_in' ? login() : forgotPassword()" ref="loginForm" class="p-4">
                        <p class="text-center mb-3" :class="mode === 'log_in' ? 'display-5' : 'display-6'">{{ $t('login.' + mode) }}</p>
                        <div class="mb-3">
                            <label for="emailInput" class="form-label">{{ $t('addresses.email') }}</label>
                            <input type="email" id="emailInput" name="email" v-model="email" class="form-control" ref="inputEmail" :disabled="runningProduction && !csrfTokenReceived" required />
                        </div>
                        <div class="mb-3" v-if="mode === 'log_in'">
                            <label for="passwordInput" class="form-label">{{ $t('general.password') }}</label>
                            <input type="password" id="passwordInput" name="password" v-model="password" class="form-control" ref="inputPassword" :disabled="runningProduction && !csrfTokenReceived" required />
                        </div>
            
                        <input type="submit" class="btn btn-primary col-12 btn-lg" :value="$t('login.button_text.' + mode)">
            
                        <p class="mb-0 mt-3 text-center">
                            <a href="#" @click="setMode('forgot_password')" class="text-decoration-none" v-if="mode === 'log_in'">{{ $t('login.forgot_password') }}</a>
                            <a href="#" @click="setMode('log_in')" class="text-decoration-none" v-else>{{ $t('login.back_to_log_in') }}</a>
                        </p>
                    </form>
                    <transition-expand>
                        <p v-if="showInformation" :class="'col-12 message-box p-3 mb-0 alert-' + informationClass" v-html="informationText"></p>
                    </transition-expand>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import axios from 'axios';
import transitionExpand from '@/components/TransitionExpand.vue';

export default {
    name: 'Login',
    data() {
        return {
            mode: 'log_in',
            email: '',
            password: '',
            showInformation: false,
            informationClass: 'danger',
            informationText: '',
            csrfTokenReceived: false,
            processingLoginRequest: false,
            processingSendEmailRequest: false,
            spinnerBoxStyles: {},
            runningProduction: process.env.NODE_ENV !== 'development',
        }
    },

    methods: {
        async login() {
            this.processingLoginRequest = true;
            this.showInformation = false;

            let loginData = {
                client: this.email,
                secret: this.password,
            }

            /* if (!this.email.length) {
                this.showAlert('Please enter your email above', 'warning');
                this.$refs.inputEmail.focus();

                return;
            }

            if (!this.password.length) {
                this.showAlert('Please enter your password', 'warning');
                this.$refs.inputPassword.focus();

                return;
            } */

            let response = await this.wsyAPI.post('login', loginData);

            if (this.wsyAPI.validateResponse(response)) {
                localStorage.authorizationBearer = response.token;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.token;
                this.processingLoginRequest = false;

                this.$router.push('/admin/dashboard');
            } else {
                if (response.message != undefined && response.message !== '') {
                    this.showAlert(response.message);
                }
                this.processingLoginRequest = false;
            }
        },

        async forgotPassword() {
            this.processingSendEmailRequest = true;
            this.showInformation = false;

            if (this.email == '') {
                this.showAlert('Please enter your email above', 'warning');
                this.$refs.inputEmail.focus();

                return;
            }

            let response = await this.wsyAPI.post('forgot-password', { email: this.email });

            if (this.wsyAPI.validateResponse(response)) {
                this.showAlert(this.$t('login.forgot_password_email_success'), 'success');
            } else {
                this.showAlert(this.$t('login.forgot_password_email_fail'));
            }

            this.processingSendEmailRequest = false;
        },

        showAlert(text, className = 'danger') {
            this.showInformation = true;
            this.informationText = text;
            this.informationClass = className;
        },

        matchDimensions() {
            let heightString = this.$refs.loginForm.clientHeight + 'px';
            let widthString = this.$refs.loginForm.clientWidth + 'px';

            this.$set(this.spinnerBoxStyles, 'height', heightString); 
            this.$set(this.spinnerBoxStyles, 'width', widthString); 
        },

        setMode(mode) {
            this.mode = mode;
        },

        async getCsrfCookie() {
            let response = await this.wsyAPI.csrfCookie();

            if (response.getStatusCode() === 204) {
                this.csrfTokenReceived = true;
            } else {
                console.log('Fetching CSRF cookie failed.');
            }
        }
    },

    created() {
        let _this = this;

        axios.get(process.env.VUE_APP_API_HOST + '/sanctum/csrf-cookie').then(function (response) {
            _this.csrfTokenReceived = true;
        }).catch(function (error) {
            console.log('Fetching CSRF cookie failed.');
        });
    },

    mounted() {
        if (this.csrfTokenReceived) {
            this.$refs.inputEmail.focus();
        }

        this.matchDimensions();
    },

    components: {
        transitionExpand,
    },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/_variables.scss";

.main-container {
    background-color: $light-1;
    .login-view {
        padding-top: 7%;

        .form-section {
            background-color: #fff;
            border-radius: 0.5rem;

            .message-box {
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
            }
        }
    }
}
</style>