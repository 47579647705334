import Vue from "vue";
import vueComputedDimensions from "vue-computed-dimensions";

const getDefaultState = () => {
    return {
        openZReport: false,
        zReportActionRequired: false,
        unintegratedPaymentTypes: [],
        transferToDeposit: [],

        counts: false,
        //countsTotals: [ ],
        currentPaymentTypeIndex: false,
        currentDenominationIndex: false,
        unintegratedIndexMapping: { },
        integratedIndexMapping: { },
        finalInputCoordinate: false,
        mustFill: false,
        //finishedFilling: false,

        isProcessingSubmitZReportRequest: false,
        isProcessingGetZReportRequest: false,
        isWaitingForRedirect: false,

        isAwaitingConfirmations: {
            difference: false,
            doNotTransferToCashDeposit: false,
            receivedFromCashDepositSinceClose: false,
            transferredToCashDepositSinceClose: false,
        },

        transferredToCashDepositSinceLastClose: false,
        //isAwaitingDifferenceConfirmation: false,
    }
}

export default {
    state: getDefaultState,

    getters: {
        unintegratedCashReportPaymentTypes: (state) => {
            return state.unintegratedPaymentTypes;
        },

        currentPaymentTypeCounts: (state) => {
            return state.counts[state.currentPaymentTypeIndex];
        },
        
        counts: (state) => {
            return state.counts;
        },

        indexOfCashPaymentType: (state) => {
            return state.unintegratedPaymentTypes.findIndex(paymentType => paymentType.interface.key === 'cash');
        },

        currentPaymentTypeTransferToDeposit: (state) => {
            return state.transferToDeposit[state.currentPaymentTypeIndex];
        },

        // Okay, not really a clean solution we're continuing on here... maybe refactor
        transferToDepositOfUnintegratedPaymentTypeAtIndex: (state) => (index) => {
            return state.transferToDeposit[index];
        },

        mustFill: (state) => {
            return state.mustFill;
        },

        nextFieldDoesntExist: (state) => {
            return state.currentDenominationIndex === false || (state.finalInputCoordinate
                && state.finalInputCoordinate[0] === state.currentPaymentTypeIndex
                && state.finalInputCoordinate[1] === state.currentDenominationIndex);
        },

        currentPaymentTypeIndex: (state) => {
            return state.currentPaymentTypeIndex;
        },

        currentPaymentType: (state) => {
            return state.unintegratedPaymentTypes[state.currentPaymentTypeIndex];
        },

        currentDenominationIndex: (state) => {
            return state.currentDenominationIndex;
        },

        currentPaymentTypeClass: (state) => {
            return state.unintegratedPaymentTypes.length === 0 || !(state.currentPaymentTypeIndex in state.unintegratedPaymentTypes)
                ? false
                : state.unintegratedPaymentTypes[state.currentPaymentTypeIndex].interface.key;
        },

        everyZReportElementDisabled: (state) => {
            return state.isProcessingSubmitZReportRequest || state.isProcessingGetZReportRequest || state.isWaitingForRedirect;
        },

        hasFinishedFilling: (state) => {
            return state.counts && state.counts.every((p) => p.every((c) => c !== ''));
        },

        isAwaitingConfirmationOfType: (state) => (type) => {
            // Equally legitimate values: undefined, false, and true
            return state.isAwaitingConfirmations[type] !== false;
        },

        isAwaitingAnyConfirmations: (state) => {
            return Object.values(state.isAwaitingConfirmations).some(v => v !== false);
        },

        valueOfAwaitingConfirmationType: (state) => (type) => {
            return state.isAwaitingConfirmations[type];
        },

        // Gets the value of the open count of the (integrated or unintegrated) payment type at index
        paymentTypeOpenValue: (state) => (absIndex) => {
            return state.openZReport.statement[absIndex].counts.open.value;
        },

        openDifferenceOfUnintegratedPaymentTypeAtIndex: (state) => (index) => {
            return state.unintegratedPaymentTypes[index]
        },

        // Gets the accounted total of the (integrated or unintegrated) payment type at index
        paymentTypeTotal: (state) => (absIndex) => {
            return (state.openZReport && state.openZReport.statement[absIndex]) ? state.openZReport.statement[absIndex].total : 0;
        },

        // Gets the current count value of the unintegrated payment type at index
        // relIndex is index of the current `count` field being selected-processed, integer non negative
        paymentTypeCurrentCountTotal: (state) => (relIndex) => {
            const typeHasClass = state.unintegratedPaymentTypes[relIndex].interface.key;
            if (typeHasClass === 'cash') {
                // hardcoded array of krona banknote nominals in cash count: 50, 100, 200, ...
                let units = state.unintegratedPaymentTypes[relIndex]['counts_format']['units'];

                // state.counts is array of user-inputed values in cash count, usually all 0 when register is being opened
                // result is calculated by "reducing" array to one number. Any empty field is considered to be 0 and ignored
                return state.counts[relIndex].reduce((previous, current, currentCountIndex) => {
                    let mutatedCurrent = (current === '' || current === undefined || current === null) ? 0 : current;
                    return previous + mutatedCurrent * units[currentCountIndex];
                }, 0)
            } else if (['autonomous_terminal', 'enclosure'].includes(typeHasClass)) {
                return state.counts[relIndex].reduce((previous, current) => {
                    let mutatedCurrent = (current === '' || current == null) ? 0 : (current.replace(',', '.') * 100);
                    return previous + mutatedCurrent;
                }, 0);
            }
        },

        // copy of paymentTypeCurrentCountTotal which returns NaN for empty or undefined fields so we know not all fields were initialized with numeric values
        paymentTypeCurrentCountTotalOrNaN: (state) => (relIndex) => {
            const typeHasClass = state.unintegratedPaymentTypes[relIndex].interface.key;
            if (typeHasClass === 'cash') {
                // hardcoded array of krona banknote nominals in cash count: 50, 100, 200, ...
                let units = state.unintegratedPaymentTypes[relIndex]['counts_format']['units'];

                // result is calculated by "reducing" array to one number. If any field is empty NaN returned
                return state.counts[relIndex].reduce((previous, current, currentCountIndex) => {
                    let mutatedCurrent = (current === '' || current === undefined || current === null) ? NaN : current;
                    return previous + mutatedCurrent * units[currentCountIndex];
                }, 0)
            } else if (['autonomous_terminal', 'enclosure'].includes(typeHasClass)) {
                return state.counts[relIndex].reduce((previous, current) => {
                    let mutatedCurrent = (current === '' || current === undefined || current === null) ? NaN : (current.replace(',', '.') * 100);
                    return previous + mutatedCurrent;
                }, 0);
            }
        },

        paymentTypeCurrentTransferTotal: (state) => (relIndex) => {
            const typeHasClass = state.unintegratedPaymentTypes[relIndex].interface.key;
            if (typeHasClass === 'cash') {
                let units = state.unintegratedPaymentTypes[relIndex]['counts_format']['units'];

                return state.transferToDeposit[relIndex].reduce((previous, current, currentCountIndex) => {
                    return previous + current * units[currentCountIndex];
                }, 0)
            }

            // Should never get here because it's always cash
            return 0;
        },

        paymentTypeCurrentRemainsInRegisterTotal: (state, getters) => (relIndex) => {
            return getters.paymentTypeCurrentCountTotal(relIndex) - getters.paymentTypeCurrentTransferTotal(relIndex);
        },

        paymentTypeDifference: (state, getters) => (relIndex) => {
            return Math.round(getters.paymentTypeCurrentCountTotal(relIndex)) - getters.paymentTypeTotal(state.unintegratedIndexMapping[relIndex]) - getters.paymentTypeOpenValue(state.unintegratedIndexMapping[relIndex]);
        },

        totalDifference: (state, getters) => {
            let totalDifference = 0;

            if (state.zReportActionRequired === 'close') {
                for (let i = 0; i < state.counts.length; i++) {
                    const difference = getters.paymentTypeDifference(i);
                    totalDifference += difference;
                }
            }

            return totalDifference;
        },

        zReportActionRequired: (state) => {
            return state.zReportActionRequired;
        },

        zReportActionRequiredIsOpen: (state) => {
            return 'open' == state.zReportActionRequired;
        },

        fieldIsFilled: (state) => (indices) => {
            return state.counts[indices[0]][indices[1]] !== '';
        },

        fieldOfCurrentPayentTypeIsFilled: (state, getters) => (index) => {
            return getters.fieldIsFilled([state.currentPaymentTypeIndex, index]);
        },

        currentFieldIsFilled: (state, getters) => {
            return getters.fieldIsFilled([state.currentPaymentTypeIndex, state.currentDenominationIndex]);
        },

        paymentTypeIsFilled: (state) => (paymentTypeIndex) => {
            return state.counts[paymentTypeIndex].every(c => c !== '');
        },

        currentPaymentTypeIsFilled: (state) => {
            return state.counts[state.currentPaymentTypeIndex].every(c => c !== '');
        },

        previousCloseCountDataOfCurrentPaymentType: (state, getters) => {
            if (!getters.register.previous_cash_close) {
                return null;
            }

            const previous = getters.register.previous_cash_close.find(c => c.payment_type_uuid === getters.currentPaymentType.uuid);

            if (previous === undefined) {
                return null;
            }
            
            return previous.amounts;
        },

        inputDisabledForCurrentPaymentType: (state, getters) => {
            if (!getters.currentPaymentType) {
                return true;
            }
            
            if (getters.zReportActionRequired === 'open' && ['autonomous_terminal', 'enclosure'].includes(getters.currentPaymentType.interface.key)) {
                return true;
            }

            return false;
        },

        nextCounts: (state, getters) => {
            return getters.counts.slice(state.currentPaymentTypeIndex + 1);
        },

        currentPaymentTypeIsFinal: (state, getters) => {
            return state.currentPaymentTypeIndex === (getters.counts.length - 1);
        },

        indexOfNextUnfilledPaymentType: (state, getters) => {
            return getters.counts.findIndex((counts, index) => index > state.currentPaymentTypeIndex && counts.some(count => count === ''));
        },

        someNextUnfilledPaymentType: (state, getters) => {
            return getters.indexOfNextUnfilledPaymentType !== -1;
        },

        currentPaymentTypeCountIsAllZero: (state, getters) => {
            return getters.currentPaymentTypeCounts.every(c => c === '0');
        },
    },

    mutations: {
        setOpenZReport (state, zReport) {
            state.openZReport = zReport;
        },

        setZReportActionRequired (state, action) {
            state.zReportActionRequired = action;
            state.mustFill = (action === 'open' ? ['cash'] : ['cash', 'autonomous_terminal', 'enclosure']);
        },

        resetCashReportState (state) {
            Object.assign(state, getDefaultState());
        },

        setUnintegratedPaymentTypes (state, unintegratedPaymentTypes) {
            state.unintegratedPaymentTypes = unintegratedPaymentTypes;
        },

        setTransferToDepositFalse (state, paymentTypeIndex) {
            state.transferToDeposit[paymentTypeIndex].fill(0);
            //Vue.set(state.transferToDeposit, paymentTypeIndex, false);
        },

        setTransferToDepositValues (state, paymentTypeIndex) {
            Vue.set(state.transferToDeposit, paymentTypeIndex, Array(state.unintegratedPaymentTypes[paymentTypeIndex].counts_format.units.length).fill(0));
        },

        setFinalInputCoordinate (state, coordinate) {
            state.finalInputCoordinate = coordinate;
        },

        nextIndex (state) {
            //const countsFormatIsntNull = state.unintegratedPaymentTypes[state.currentPaymentTypeIndex]['counts_format']['units'] !== null;
            const nextDenominationIsDefined = state.unintegratedPaymentTypes[state.currentPaymentTypeIndex]['counts_format']['units']?.[state.currentDenominationIndex + 1] !== undefined;
            
            if (nextDenominationIsDefined) {
                state.currentDenominationIndex++;
            } else {
                for (let i = state.currentPaymentTypeIndex + 1; i < state.unintegratedPaymentTypes.length; i++) {
                    if (state.mustFill.includes(state.unintegratedPaymentTypes[i].interface.key)) {
                        state.currentPaymentTypeIndex = i;
                        state.currentDenominationIndex = 0;
                        return;
                    }
                }
            }
        },

        setPaymentTypeIndex (state, paymentTypeIndex) {
            state.currentDenominationIndex = 0;
            state.currentPaymentTypeIndex = paymentTypeIndex;
        },

        setDenominationIndex (state, denominationIndex) {
            state.currentDenominationIndex = denominationIndex;
        },

        setInitialCurrentIndices (state) {
            const firstPaymentTypeIndex = state.unintegratedPaymentTypes.findIndex(p => state.mustFill.includes(p.interface.key));

            if (firstPaymentTypeIndex !== -1) {
                state.currentPaymentTypeIndex = firstPaymentTypeIndex;
                state.currentDenominationIndex = 0;
            }
        },

        setIsProcessingSubmitRequest (state, value) {
            state.isProcessingSubmitZReportRequest = value;
        },

        setIsProcessingGetRequest (state, value) {
            state.isProcessingGetRequest = value;
        },

        setIsWaitingForRedirect (state, value) {
            state.isWaitingForRedirect = value;
        },

        setIsAwaitingConfirmation (state, data) {
            Vue.set(state.isAwaitingConfirmations, data.type, data.value);
        },

        setIsAwaitingDifferenceConfirmation (state, value) {
            Vue.set(state.isAwaitingConfirmations, 'difference', value);
        },

        // Used to ask cashiers for confirmation that they don't want to register the transfer of any cash to the deposit.
        setIsAwaitingDoNotTransferToCashDepositConfirmation (state, value) {
            Vue.set(state.isAwaitingConfirmations, 'doNotTransferToCashDeposit', value);
        },

        // Used to ask the cashier whose opening cash count does not match the previous cash closing count whether the
        // difference is the consequence of someone transferring currency from the cash deposit to the register while the
        // register was closed
        setIsAwaitingReceivedFromCashDepositConfirmation (state, value) {
            Vue.set(state.isAwaitingConfirmations, 'receivedFromCashDepositSinceClose', value);
        },

        setIsAwaitingTransferredToCashDepositConfirmation (state, value) {
            Vue.set(state.isAwaitingConfirmations, 'transferredToCashDepositSinceClose', value);
        },

        setIsAwaitingNoConfirmations (state) {
            for (const [key, value] of Object.entries(state.isAwaitingConfirmations)) {
                Vue.set(state.isAwaitingConfirmations, key, false);
            }
        },

        setUnintegratedIndexMapping (state, mapping) {
            state.unintegratedIndexMapping = mapping;
        },

        setIntegratedIndexMapping (state, mapping) {
            state.integratedIndexMapping = mapping;
        },

        /* setFinishedFilling (state, value) {
            state.finishedFilling = value;
        }, */

        setAllCounts (state, value) {
            state.counts = value;

            state.transferToDeposit = Array(state.unintegratedPaymentTypes.length).fill(false);
            state.unintegratedPaymentTypes.forEach((p, index) => {
                if (p.interface.key === 'cash') {
                    let transferCounts = Array(state.counts[index].length).fill(0);
                    transferCounts.fill(0);

                    Vue.set(state.transferToDeposit, index, transferCounts);
                }
            });
        },

        setPaymentTypeCounts (state, { paymentTypeIndex, countsArray }) {
            //state.counts[paymentTypeIndex] = countsArray;
            Vue.set(state.counts, paymentTypeIndex, countsArray);
        },

        setCountValue (state, value) {
            Vue.set(state.counts[state.currentPaymentTypeIndex], state.currentDenominationIndex, value);
        },

        zeroFillAllCounts (state) {
            state.counts.forEach((paymentTypeCounts, index, allCounts) => {
                //state.counts[index].fill('0');
                Vue.set(state.counts, index, paymentTypeCounts.fill('0'));
            });
            /* for (countsArray of (state.counts, countsIndex)) {
                
            } */
        },

        moveQuantityAtDenominationIndex (state, { index, qty }) {
            //Vue.set(state.counts[state.currentPaymentTypeIndex], index, state.counts[state.currentPaymentTypeIndex][index] - qty);
            Vue.set(state.transferToDeposit[state.currentPaymentTypeIndex], index, state.transferToDeposit[state.currentPaymentTypeIndex][index] + qty);
        },

        setCashDepositTransferSinceLastClose (state) {
            state.transferredToCashDepositSinceLastClose = true;
        },
    }
}