//import axios from "axios.js";
import Vue from "vue";

const getDefaultState = () => {
    return {
        list: [],
        selectedRow: false,
        previousExternalOrderUuid: false,

        refreshInterval: false,
        isProcessingFetchRequest: false,
        getParams: {
            for_pos: 1,
            limit_to_day: 'today',
        },
    }
}

export default {
    state: getDefaultState,

    getters: {
        externalOrders: (state) => {
            return state.list;
        },

        currentExternalOrder: (state) => {
            return (state.selectedRow !== false) ? state.list[state.selectedRow] : false;
        },

        currentExternalOrderUuid: (state) => {
            //return (state.selectedRow !== false) ? state.list[state.selectedRow]['uuid'] : false;
            return state.list?.[state.selectedRow]?.['uuid'] ?? false;
        },

        limitingExternalOrdersToDay: (state) => {
            return state.getParams.limit_to_day;
        },

        currentExternalOrderIsOwnedByUs: (state, getters) => {
            return getters.currentExternalOrder && getters.currentExternalOrder.order !== null;
        },

        currentExternalOrderIsWoltProduction: (state, getters) => {
            return getters.currentExternalOrder.source === 'Wolt' && getters.currentExternalOrder.status.name === 'production';
        },

        currentExternalOrderIsFromWolt: (state, getters) => {
            return getters.currentExternalOrder.source === 'Wolt';
        },

        currentExternalOrderIsFromNta: (state, getters) => {
            return getters.currentExternalOrder.source === 'NTA';
        },

        currentExternalOrderIsFromWoltReady: (state, getters) => {
            return getters.currentExternalOrder.source === 'Wolt' && getters.currentExternalOrder.status.name === 'ready';
        },

        currentExternalOrderIsPaid: (state, getters) => {
            return getters.currentExternalOrder && getters.currentExternalOrder.unpaid === 0;
        },

        currentExternalOrderIsPartiallyPaid: (state, getters) => {
            return getters.currentExternalOrder &&
                getters.currentExternalOrder.unpaid !== 0 &&
                getters.currentExternalOrder.unpaid !== getters.currentExternalOrder.total;
        },

        currentExternalOrderHasStateId: (state, getters) => (value) => {
            return getters.currentExternalOrder && getters.currentExternalOrder.status.state_id === value;
        },

        currentExternalOrderHasStateIdInRange: (state, getters) => (min, max) => {
            let value = getters.currentExternalOrder &&
                getters.currentExternalOrder.status.state_id >= min &&
                getters.currentExternalOrder.status.state_id <= max;

            return value;
        },

        currentExternalOrderCanBeOvertaken: (state, getters) => (min, max) => {
            if (getters.currentExternalOrder.source === 'NTA') {
                return getters.currentExternalOrderHasStateIdInRange(2, 7);
            }
            if (getters.currentExternalOrder.source === 'Wolt') {
                return getters.currentExternalOrder && ['delivered', 'ready'].includes(getters.currentExternalOrder.status.name); 
            }
        },

        isProcessingGetExternalOrdersRequest: (state) => {
            return state.isProcessingFetchRequest;
        },

        externalOrdersFetchParams: (state) => {
            return state.getParams;
        },

        newExternalOrdersCount: (state) => {
            return state.list.filter(externalOrder => externalOrder.seen === false).length;
        },

        anyOrdersPendingConfirmation: (state) => {
            return state.list.some(externalOrder => externalOrder.status.state_id === 1);
        },

        anyExternalOrdersLocked: (state, getters) => {
            return state.list.some(externalOrder => {
                return externalOrder?.order?.retaining_register != null && externalOrder.order.retaining_register.identifier !== getters.register.identifier
            });
        },
    },

    mutations: {
        setIndexOfCurrentExternalOrder (state, index) {
            state.selectedRow = index;
        },

        setExternalOrders (state, externalOrders) {
            state.list = externalOrders;
        },

        updateDataOfExtOrderWithUuid (state, { uuid, data }) {
            const index = state.list.findIndex(externalOrder => externalOrder.uuid === uuid);

            if (index !== -1) {
                Vue.set(state.list, index, data);
            } else {
                console.log('update failed');
            }
        },

        // This is currently not used, as we're refreshing irrespective of which view the user is in
        resetExternalOrdersState (state) {
            Object.assign(state, getDefaultState());
        },

        setIsProcessingGetExternalOrdersRequest (state, value) {
            state.isProcessingFetchRequest = value;
        },

        setPreviousExternalOrderUuid (state, uuid) {
            state.previousExternalOrderUuid = uuid;
        },

        setLimitToDay (state, day) {
            state.getParams.limit_to_day = day;
        },

        setExternalOrderWithUuidAsSeen (state, uuid) {
            let index = state.list.findIndex(externalOrder => externalOrder.uuid === uuid);

            if (index !== -1) {
                state.list[index].seen = true;
            }
        },

        setCurrentExternalOrderLastUserAction (state, status) {
            let index = state.list.findIndex(externalOrder => externalOrder.uuid === this.getters.currentExternalOrder.uuid);

            if (index !== -1) {
                state.list[index].last_user_action = status;
            }
        },
    },

    actions: {
        changeState({ commit, getters }, { uuid, data }) {
            data.register_uuid = getters.register.uuid;
            data.cashier_uuid = getters.cashier.uuid;

            return axios.put('external-orders/' + uuid + '/change-state', data);
        },

        getExternalOrders({ commit, getters }, { firstRequest }) {
            // We only show the spinner when we've no list of external orders
            commit('setPreviousExternalOrderUuid', getters.currentExternalOrderUuid);

            return axios.get('external-orders', { params: getters.externalOrdersFetchParams });
        },
    },
}