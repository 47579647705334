import Vue from 'vue';
//import axios from 'axios';

export default {
    state: {
        notifications: [],
    },

    getters: {
        notifications: (state) => {
            return state.notifications;
        }
    },

    mutations: {
        successNotify(state, text) {
            state.notifications.push({ type: 'success', text });
        },

        infoNotify(state, text) {
            state.notifications.push({ type: 'info', text });
        },

        warningNotify(state, text) {
            state.notifications.push({ type: 'warning', text });
        },

        dangerNotify(state, text) {
            state.notifications.push({ type: 'danger', text });
        },

        dismissNotification(state, notification) {
            state.notifications.splice(notification, 1);
        },
    },

    actions: {
        
    },
}