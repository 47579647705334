import Vue from 'vue';
//import axios from 'axios';

export default {
    state: {
        headerText: null,
        queryText: null,
        confirmClosure: null,
    },

    getters: {
        takeOverQueryActive: (state) => {
            return (state.queryText || state.headerText) && state.confirmClosure;
        }
    },

    mutations: {
        setTakeOverQuery(state, { headerText, queryText, confirmClosure }) {
            state.headerText = headerText ?? null;
            state.queryText = queryText ?? null;
            state.confirmClosure = confirmClosure;
        },

        setTakeOverQueryNotActive(state) {
            state.headerText = null;
            state.queryText = null;
            state.confirmClosure = null;
        },
    },

    actions: {
        
    },
}